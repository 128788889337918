import { Checkbox, FormControlLabel, Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  SidebarContainer,
  LoginContainer,
  Description,
  LoginInput,
  Container,
  Logotype,
  LoginBox,
  Sidebar,
  Row,
} from "./styles";
import { BootstrapButton } from "../../components/BootstrapButton/styles";

export default function Login() {
  return (
    <Container>
      <SidebarContainer>
        <Sidebar>
          <Logotype>Logo</Logotype>
          <Description>Gerencie e organize seus números</Description>
        </Sidebar>
      </SidebarContainer>
      <LoginContainer>
        <LoginBox>
          <LoginInput id="outlined-basic" label="Usuário" variant="outlined" />
          <LoginInput
            id="outlined-password-input"
            label="Senha"
            type="password"
            autoComplete="current-password"
          />
          <Row>
            <FormControlLabel
              control={<Checkbox fontSize={15} defaultChecked />}
              label={
                <Box component="span" fontSize={15}>
                  Manter Conectado
                </Box>
              }
            />
            <Box component="span" fontSize={15}>
              <Link to="forgotpassword">Esqueci a Senha</Link>
            </Box>
          </Row>
          <BootstrapButton>Entrar</BootstrapButton>
          <Box component="span" fontSize={15} sx={{ marginTop: "1em" }}>
            Não tem uma conta? <Link to="signup">Crie uma</Link>
          </Box>
        </LoginBox>
      </LoginContainer>
    </Container>
  );
}
