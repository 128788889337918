import React from "react";
import ResponsiveAppBar from "../../components/ResponsiveHeader";
import CustomTableNumber from "../../components/CustomTableNumber";
import {
  BodyContainer,
  ButtonsInline,
  Divider,
  Subtitle,
  Title,
  TitleInline,
  TitleInlineButtonsContainer,
} from "./styles";
import CloudDownload from "@mui/icons-material/CloudDownload";
import { BootstrapButton } from "../../components/BootstrapButton/styles";
import SearchInput from "../../components/SearchInput";
import Button360Dialog from "../../components/Button360Dialog";

export default function Home() {
  return (
    <>
      <ResponsiveAppBar></ResponsiveAppBar>
      <BodyContainer>
        <TitleInlineButtonsContainer>
          <TitleInline>
            <Title>Histórico de telefones</Title>
            <Subtitle>Gerencie seus cadastros onboarding</Subtitle>
          </TitleInline>
          <ButtonsInline>
            <BootstrapButton
              backgroundcolor={"white"}
              startIcon={<CloudDownload />}
            >
              Baixar
            </BootstrapButton>
            <Button360Dialog>Criar Novo</Button360Dialog>
          </ButtonsInline>
        </TitleInlineButtonsContainer>
        <Divider></Divider>
        <TitleInlineButtonsContainer>
          <SearchInput></SearchInput>
        </TitleInlineButtonsContainer>
        <CustomTableNumber></CustomTableNumber>
      </BodyContainer>
    </>
  );
}
