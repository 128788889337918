import styled from "styled-components";

export const BodyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5% 10% 0 10%;
`;

export const TitleInlineButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 2em;
`;

export const TitleInline = styled.div``;

export const Title = styled.h1`
  font-weight: 400;
  font-family: "Inter";
`;

export const Subtitle = styled.h2`
  font-weight: 200;
  font-family: "Inter";
  color: #4b4b4b;
  font-size: 17px;
`;

export const ButtonsInline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 2em;
  width: 30%;
`;

export const Divider = styled.hr`
  width: 100%;
  margin-bottom: 2em;
  border: 1px solid rgb(234, 234, 234, 0.8);
`;
