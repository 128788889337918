import React from "react";
import ApplicationRoutes from "./routes";

import GlobalStyles from "./styles/global";

function App() {
  return (
    <>
      <ApplicationRoutes />
      <GlobalStyles />
    </>
  );
}

export default App;
