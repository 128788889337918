import { styled as muiStyled } from "@mui/material/styles";
import styled from "styled-components";
import { TextField } from "@mui/material";

export const Container = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
`;

export const Logotype = styled.h1`
  font: 800;
  font-family: "Merienda One";
  font-size: 60px;
  color: #fff;
`;

export const LoginInput = muiStyled(TextField)({
  width: "100%",
  marginTop: "1em",
});

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
export const LoginBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SidebarContainer = styled.div`
  width: 30%;
`;
export const Sidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #422f7e;
  height: 100%;
`;

export const Description = styled.span`
  font: 200;
  color: #fff;
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 60%;
  margin-top: 1em;
  font-size: 15px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1em;
  gap: 3em;
`;
