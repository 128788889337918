import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { StyledTableCell, StyledTableRow } from "./styles";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "(55) 35 9999-999",
    "Jhon Smith",
    "Ativo",
    "Badge text",
    "Ipsum Lorem Ipsum Lorem Ipsum Lorem"
  ),
  createData(
    "(55) 35 9999-999",
    "Jhon Smith",
    "Inativo",
    "Badge text",
    "Ipsum Lorem Ipsum Lorem Ipsum Lorem"
  ),
  createData(
    "(55) 35 9999-999",
    "Jhon Smith",
    "Ativo",
    "Badge text",
    "Ipsum Lorem Ipsum Lorem Ipsum Lorem"
  ),
  createData(
    "(55) 35 9999-999e",
    "Jhon Smith",
    "Inativo",
    "Badge text",
    "Ipsum Lorem Ipsum Lorem Ipsum Lorem"
  ),
  createData(
    "(55) 35 9999-999",
    "Jhon Smith",
    "Ativo",
    "Badge text",
    "Ipsum Lorem Ipsum Lorem Ipsum Lorem"
  ),
];

export default function CustomTableNumber() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Telefone</StyledTableCell>
            <StyledTableCell align="right">Nome</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell>
            <StyledTableCell align="right">Identificador</StyledTableCell>
            <StyledTableCell align="right">Descrição</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
