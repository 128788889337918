import { styled as muiStyled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const BOXSHADOW =
  "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)";

export const BootstrapButton = muiStyled(Button)(({ backgroundcolor }) => ({
  width: "100% !important",
  backgroundColor: backgroundcolor === "white" ? "#fff" : "#7F56D8",
  color: backgroundcolor === "white" ? "#464E5F" : "#fff",
  boxShadow: backgroundcolor === "white" ? BOXSHADOW : "none",
  padding: "15px",
  marginTop: "1em",
  "&:hover": {
    backgroundColor: backgroundcolor === "white" ? "#f8f8f8" : "#7750ca",
    borderColor: "#7750ca",
    boxShadow: backgroundcolor === "white" ? BOXSHADOW : "none",
  },
  borderRadius: "10px",
}));
