import { ConnectButton } from "360dialog-connect-button";
import styled from "styled-components";
import { BootstrapButton } from "../BootstrapButton/styles";
import axios from 'axios';

const StyledConnectButton = styled(ConnectButton)`
  opacity: 0;
  width: 100%;
  position: absolute;
  height: 100%;
`;

export default function Button360Dialog() {
  const handleCallback = async (callbackObject) => {
    console.log("client ID: " + callbackObject.client);
    console.log("channel IDs: " + callbackObject.channels);
    console.log({callbackObject})
    await axios.post('https://ens17bj97dhxvng.m.pipedream.net', callbackObject)
  };
  return (
    <BootstrapButton>
      <StyledConnectButton partnerId={"d1wNqWPA"} callback={handleCallback} />
      Criar Novo
    </BootstrapButton>
  );
}
